import CrudService from "@/services/shared/http/CrudService";

export default class ExternalEventService extends CrudService {
  constructor(params = {}) {
    super("event", params);
  }
  index(params = {}) {
    return this.api.GET("event/get-list", params)
  }
  makeEventUpcoming(data, params = {}) {
    return this.api.POST('event/set-upcoming', data, params)
  }
}
