import CrudService from "@/services/shared/http/CrudService";

export default class SharedService extends CrudService {
  constructor(params = {}) {
    super("shared", params);
  }

  getLanguagesList() {
    return this.api.GET('langs')
  }
  getTopicsList() {
    return this.api.GET('topic')
  }
}
