<template>
  <div class="">
    <EventForm ref="EventForm" v-model="formData" @formSubmitted="storeExternalEvent"/>
  </div>
</template>

<script>
import EventForm from "@/views/components/event/EventForm";
import EventService from "@/services/EventService";

export default {
  name: "CreateEvent",
  components: {
    EventForm
  },
  data() {
    return {
      eventService: new EventService(),
      formData: {
        // what key i have
      },
    }
  },
  methods: {
    storeExternalEvent(e) {
      this.eventService.create(e).then(res => {
        this.$router.push('/event')
      }).catch(err => {
        this.loading = false
      })
    },
  }
}
</script>

<style scoped>
</style>
